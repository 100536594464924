import React from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(timezone)
dayjs.extend(utc)

type HeaderDateProps = {
  className?: string
}

const HeaderDate = (props: HeaderDateProps) => {
  const { className } = props

  const [date, setDate] = React.useState(new Date())

  React.useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <span className={cx('header-date', className)}>
      {/* @ts-ignore */}
      {dayjs(date).tz('America/New_York').format('MMMM D, YYYY h:mm A')} EST.
      <style jsx>{`
        .header-date {
          @apply flex-1 font-inter text-[15px] font-medium leading-[18.15px] tracking-[-0.3px] text-gray-900;
        }
      `}</style>
    </span>
  )
}

export default HeaderDate
