import cx from 'classnames'

type ContainerProps = {
  className?: string
  children: React.ReactNode
}

function Container(props: ContainerProps) {
  const { className, children } = props
  return (
    <div className={cx('p-container', className)}>
      {children}
      <style jsx>{`
        .p-container {
          @apply grid gap-x-4;
          grid-template-columns: 1fr calc(100% - 2rem) 1fr;
        }

        @screen xl {
          .p-container {
            @apply gap-x-8;
            grid-template-columns: 1fr calc(1280px - 4rem) 1fr;
          }
        }
      `}</style>
      <style jsx global>{`
        .p-container > * {
          grid-column: 2;
        }
      `}</style>
    </div>
  )
}

export default Container
