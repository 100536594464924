import cx from 'classnames'

type ButtonProps = {
  children: React.ReactNode | string
  onClick?: () => void
  className?: string
  disabled?: boolean
  outline?: boolean
  isLink?: boolean
}

function Button(props: ButtonProps) {
  const { children, className, disabled, onClick, outline, isLink} = props
  const BtnElement = isLink ? 'a' : 'button'
  return (
    <BtnElement className={cx('button', { disabled, 'btn-outline': outline  }, className)} onClick={onClick}>
      {children}
      <style jsx>{`
        .button {
          @apply cursor-pointer border border-solid border-transparent bg-black py-3 px-10 font-poppins font-semibold text-white transition-opacity duration-150 ease-in-out;
          border-radius: 5px;
        }

        .button.btn-outline {
          @apply text-black bg-transparent border-gray-900;
        }

        .button:hover:not(.disabled) {
          opacity: 0.8;
        }
        .button.disabled {
          @apply cursor-not-allowed bg-gray-700;
        }
      `}</style>
    </BtnElement>
  )
}

export default Button
