import { SvgProps } from '../types'

function DealsDatabaseIcon(props: SvgProps) {
  const { className } = props
  return (
    <svg className={className} width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.732422 2.53555H3.2502V0.0117188H0.732422V2.53555Z" fill="currentColor"/>
      <path d="M5.13867 2.52383H7.65645V0H5.13867V2.52383Z" fill="currentColor"/>
      <path d="M9.48242 2.52383H12.0002V0H9.48242V2.52383Z" fill="currentColor"/>
      <path d="M0.673828 6.36172H3.19161V3.83789H0.673828V6.36172Z" fill="currentColor"/>
      <path d="M5.08008 6.35196H7.59786V3.82812H5.08008V6.35196Z" fill="currentColor"/>
      <path d="M9.42383 6.35196H11.9416V3.82812H9.42383V6.35196Z" fill="currentColor"/>
      <path d="M0.726562 10.0004H3.24434V7.47656H0.726562V10.0004Z" fill="currentColor"/>
      <path d="M5.13281 9.98867H7.65059V7.46484H5.13281V9.98867Z" fill="currentColor"/>
      <path d="M9.47852 9.98867H11.9963V7.46484H9.47852V9.98867Z" fill="currentColor"/>
    </svg>
  )
}

export default DealsDatabaseIcon
