import * as React from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'

function NProgressContainer() {
  const timer = React.useRef<NodeJS.Timeout | null>(null)
  React.useEffect(() => {
    const routeChangeStart = () => {
      clearTimeout(timer.current as NodeJS.Timeout)
      timer.current = setTimeout(NProgress.start, 300)
    }

    const routeChangeEnd = () => {
      clearTimeout(timer.current as NodeJS.Timeout)
      NProgress.done()
    }

    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeEnd)
    return () => {
      clearTimeout(timer.current as NodeJS.Timeout)
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeEnd)
      Router.events.off('routeChangeError', routeChangeEnd)
    }
  }, [])
  return (
    <style jsx global>{`
      #nprogress {
        pointer-events: none;
      }
      #nprogress .bar {
        background-color: black;
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
      }
      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px #dbe2e9, 0 0 5px black;
        opacity: 1;
        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
      }

      .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
      }

      .nprogress-custom-parent #nprogress .bar {
        position: absolute;
      }
    `}</style>
  )
}

export default NProgressContainer
