import Link from 'next/link'
import cx from 'classnames'
import css from 'styled-jsx/css'

import LogoSvg from '../assets/LogoSvg'

type LogoProps = {
  color?: string
  isHeader?: boolean
}

function Logo(props: LogoProps) {
  const { isHeader } = props
  const color = props.color ? `text-${props.color}` : 'text-black'
  const colorSubtitle = props.color ? `text-${props.color}` : 'text-gray-900'
  return (
    <Link href="/">
      <a className="logo">
        <LogoSvg className={cx('logoSvg', isHeader ? 'w-[120px]' : null, components.className)} />
        <span className="logo-subtitle">{`Exclusive Energy Transition News`}</span>
        <style jsx>{`
          .logo {
            @apply flex flex-1 items-center justify-center flex-col ${color};
          }
          .logo-subtitle {
            @apply font-inter font-medium ${colorSubtitle} mt-2;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 2px;
          }
        `}</style>
        {components.styles}
      </a>
    </Link>
  )
}

const components = css.resolve`
  .logoSvg {
    @apply max-w-[255px] md:w-auto mx-auto;
  }
`

export default Logo
