import { SvgProps } from '../types'

function LogoSvg(props: SvgProps) {
  const { className } = props
  return (
    <svg className={className} viewBox="0 0 255 39" xmlns="http://www.w3.org/2000/svg">
      <path d="M92.876 8.88379V37.3617H83.6003V35.4008C83.4403 35.5282 83.3549 35.5947 83.2735 35.6625C80.8149 37.6898 77.9672 38.4099 74.8414 38.1035C68.267 37.4729 63.8801 32.298 63.0013 26.4682C62.3233 21.9931 63.2468 17.8814 66.1 14.3271C68.9302 10.8013 72.6987 9.20247 77.1915 9.42758C79.6515 9.52165 81.9816 10.5566 83.7007 12.3188C86.577 11.223 89.4587 10.149 92.3403 9.06415C92.5085 9.0099 92.6712 8.95024 92.876 8.88379ZM78.0946 29.4177C81.337 29.4326 83.858 26.9767 83.8648 23.7967C83.8729 20.6452 81.3737 18.1744 78.1584 18.1554C74.8861 18.1364 72.338 20.5963 72.334 23.7818C72.3299 26.9672 74.8427 29.4041 78.1014 29.4177H78.0946Z" fill="currentColor"/>
      <path d="M187.79 23.7944C187.725 30.3037 183.405 35.8826 176.976 37.5574C171.465 38.9935 166.329 38.1337 161.96 34.3448C154.773 28.1163 155.752 17.0669 163.859 11.8649C170.232 7.7668 178.944 8.85439 184.116 14.394C186.482 16.9521 187.794 20.3098 187.79 23.7944ZM172.417 29.3544C175.663 29.3747 178.198 26.9351 178.217 23.77C178.236 20.67 175.696 18.1802 172.486 18.1517C169.309 18.1246 166.753 20.5927 166.716 23.7334C166.679 26.8741 169.187 29.3381 172.417 29.3585V29.3544Z" fill="currentColor"/>
      <path d="M59.5532 27.1784H40.7903C41.2189 28.2443 41.8752 29.0294 42.8245 29.5515C44.3012 30.3652 45.9096 30.4533 47.5409 30.3313C49.1233 30.2198 50.6171 29.5599 51.7652 28.4653L58.3314 33.076C57.471 34.2006 56.4143 35.1604 55.2123 35.9089C53.0738 37.265 50.7101 37.9254 48.2068 38.1139C44.7528 38.3742 41.4168 37.9213 38.348 36.2153C34.4452 34.0456 32.1507 30.7069 31.432 26.3159C30.7865 22.3561 31.4686 18.6336 33.8132 15.3207C36.2773 11.8396 39.7312 9.99535 43.9229 9.50309C47.1409 9.1261 50.2233 9.61429 53.0507 11.266C56.7976 13.4466 58.9253 16.769 59.6874 20.9932C60.0565 22.9726 60.0275 25.0056 59.602 26.9736C59.5966 27.0306 59.5776 27.0889 59.5532 27.1784ZM51.0329 20.8942C50.4104 18.4275 48.3817 16.9928 45.7441 17.0511C43.4496 17.1026 41.1741 18.7137 40.6425 20.8942H51.0329Z" fill="currentColor"/>
      <path d="M126.524 37.3956H115.64L108.644 23.7872L115.64 10.2168H126.524L118.773 23.8062L126.524 37.3956Z" fill="currentColor"/>
      <path d="M220.694 8.88379V37.3617H211.415V35.4008C211.255 35.5282 211.17 35.5947 211.088 35.6625C208.629 37.6898 205.781 38.4099 202.657 38.1035C196.083 37.4729 191.694 32.298 190.817 26.4682C190.139 21.9931 191.061 17.8814 193.914 14.3271C196.744 10.8013 200.513 9.20247 205.007 9.42758C207.467 9.52138 209.797 10.5564 211.516 12.3188C214.393 11.223 217.276 10.149 220.157 9.06415C220.327 9.0099 220.49 8.95024 220.694 8.88379ZM205.913 29.4177C209.154 29.4326 211.675 26.9767 211.683 23.7967C211.683 20.6452 209.191 18.1744 205.975 18.1554C202.703 18.1364 200.156 20.5963 200.152 23.7818C200.148 26.9672 202.661 29.4041 205.92 29.4177H205.913Z" fill="currentColor"/>
      <path d="M29.3092 12.0757C28.9173 7.79722 26.8682 4.60499 23.0373 2.61289C20.3251 1.20527 17.3959 0.787595 14.3881 0.782171C9.75752 0.77313 5.12738 0.77313 0.497685 0.782171H0V37.3586H10.2168V26.7052H14.0423C16.4453 26.7052 18.8266 26.5221 21.1442 25.8305C24.9887 24.6819 27.6371 22.3047 28.7464 18.372C29.3206 16.3259 29.5114 14.1912 29.3092 12.0757ZM15.7862 18.1035C14.9902 18.212 14.1711 18.1469 13.3616 18.1523C12.3296 18.1523 11.2989 18.1523 10.2425 18.1523V9.29706H15.1679C17.537 9.32147 19.2578 11.0505 19.4423 13.5701C19.5197 14.6481 19.1855 15.7154 18.507 16.5567C17.8285 17.3981 16.8562 17.9508 15.7862 18.1035Z" fill="currentColor"/>
      <path d="M98.4351 3.57194V37.3589H107.928V0L98.4351 3.57194Z" fill="currentColor"/>
      <path d="M140.647 28.3499V0.943359L130.439 4.78381V37.3584H155.438V28.3499H140.647Z" fill="currentColor"/>
      <path d="M245.722 3.50034V12.2322C245.56 12.0966 245.477 12.0234 245.395 11.9515C243.713 10.4241 241.549 9.53321 239.278 9.43323C234.861 9.21762 231.142 10.7744 228.324 14.2107C225.293 17.9156 224.352 22.2212 225.205 26.8861C225.938 30.9137 227.998 34.1331 231.519 36.3109C235.722 38.9133 241.714 38.9431 245.57 35.523C245.599 35.4973 245.649 35.4973 245.747 35.4702V37.3511H254.999V0.00976562L245.722 3.50034ZM240.236 29.4193C236.981 29.4111 234.467 26.9661 234.463 23.7942C234.459 20.6223 237 18.1448 240.273 18.1556C243.487 18.1678 245.991 20.6291 245.993 23.7847C245.993 26.9607 243.468 29.4274 240.229 29.4193H240.236Z" fill="currentColor"/>
    </svg>
  )
}

export default LogoSvg
