import { useEffect } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import Header from './Header'
import { useAuthContext, useUser } from '../context/Auth'
import NProgressContainer from './NProgress'

const Footer = dynamic(() => import('./Footer'))

type LayoutProps = {
  className?: string
  children: React.ReactNode
}

function Layout(props: LayoutProps) {
  const { className, children } = props
  const router = useRouter()
  const user = useUser()
  const auth = useAuthContext()

  useEffect(() => {
    /* recording user page view activity */
    auth.onActivityEvent('view', { path: router.asPath, query: router.query })
  }, [router.asPath])

  return (
    <div className={cx('app', className)}>
      <NProgressContainer />
      <Header />
      <div className="main">{children}</div>
      {/* @ts-ignore */}
      <Footer />
      {/* <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.markerConfig = {
            destination: '6256d61a455d7d0da2d20d68',
            source: 'snippet'
          };
          !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
          `,
        }}
      /> */}
    </div>
  )
}

export default Layout
